import "@css/app.css";

/* JS */
import "lazysizes";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;
window.clearAllBodyScrollLocks = clearAllBodyScrollLocks;

import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

Array.from(document.querySelectorAll(".js-slider")).forEach((el) => {
  const slideEl = el.querySelector(".keen-slider");
  var slider = new KeenSlider(slideEl, {
    loop: true,
    created: () => {},
  });

  const prevButton = el.querySelector(".prev-slide");
  if (prevButton) {
    prevButton.addEventListener("click", () => {
      slider.prev();
    });
  }

  const nextButton = el.querySelector(".next-slide");
  if (nextButton) {
    nextButton.addEventListener("click", () => {
      slider.next();
    });
  }
});

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const header = document.querySelector(".site-header");

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  doc.style.setProperty("--header-height", `${header.offsetHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

window.appHeight = appHeight;

const FULL_NAV_WIDTH = 1024;
const SITE_HEADER = document.querySelector(".site-header");
let windowWidth = window.innerWidth;

class InlineVideo {
  constructor(el) {
    this._el = el;
    this._video = el.dataset.video;
    this._image = el.querySelector(".js-video__placeholder");

    this._el.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick() {
    this._el.insertAdjacentHTML(
      "beforeend",
      `<iframe src="${this._video}?autoplay=1&rel=0" frameborder="0"></iframe>`
    );
    this._image.classList.add("fade-out");

    setTimeout(() => {
      this._image.remove();
    }, 700);

    this._el.removeEventListener("click", this.handleClick);
  }
}

Array.from(document.querySelectorAll(".js-video")).forEach((video) => {
  new InlineVideo(video);
});

function debounce(method, delay) {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
}

document.addEventListener("scroll", () => {
  debounce(updateHeader, 100);
});

document.addEventListener("resize", () => {
  debounce(updateWindowWidth, 100);
});

let lastUpdatePosition = 0;

function updateHeader() {
  let innerWidth = window.innerWidth;
  let scrollY = window.scrollY;
  let minScrollOffset = 400;
  let minLastUpdateDelta = 200;

  let difference = Math.abs(scrollY - lastUpdatePosition);

  if (
    (innerWidth >= FULL_NAV_WIDTH && difference > minLastUpdateDelta) ||
    scrollY == 0
  ) {
    if (scrollY > minScrollOffset) {
      SITE_HEADER.classList.add("is-sticky");
    } else {
      SITE_HEADER.classList.remove("is-sticky");
    }
    lastUpdatePosition = scrollY;
  }

  setTimeout(() => {
    document.documentElement.style.setProperty(
      "--header-height",
      `${header.offsetHeight}px`
    );
  }, 500);
}

Array.from(document.querySelectorAll(".js-store-grid")).forEach((el) => {
  const form = el.querySelector("form");
  let grid = el.querySelector(".grid");
  const blockId = el.id;

  form.addEventListener("input", () => {
    let path = `${window.location.origin}${window.location.pathname}`;
    let pathWithQuery = `${path}?filter=${el.querySelector("select").value}`;
    grid.classList.add("opacity-50", "pointer-events-none");
    fetch(pathWithQuery)
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, "text/html");
        let newGrid = doc.querySelector(`#${blockId} .grid`);
        grid.replaceWith(newGrid);
        grid = newGrid;
      });
  });
});

Array.from(document.querySelectorAll(".js-open-gallery")).forEach((el) => {
  const id = el.dataset.id;
  const images = JSON.parse(
    document.querySelector(`#gallery-${id}`).textContent
  );

  const items = [];
  images.forEach((i) => {
    const parts = i.split("|");
    items.push({
      src: parts[0],
      type: "image",
      caption: parts[1],
    });
  });

  el.addEventListener("click", () => {
    new Fancybox(items);
  });
});

Fancybox.bind("[data-fancybox]", {
  // Your custom options
});

// function handleLoadMorePosts() {
//   let loadMoreButton = document.querySelector(".js-load-more");
//   if (loadMoreButton) {
//     loadMoreButton.addEventListener("click", (event) => {
//       event.preventDefault();
//       loadMore(event.target.href);
//       loadMoreButton.innerHTML = "Loading...";
//       loadMoreButton.disabled = true;
//       window.history.pushState({}, "", event.target.href);
//     });
//   }
// }

// handleLoadMorePosts();

// async function loadMore(url, append = true) {
//   const response = await fetch(url);
//   const body = await response.text();
//   const html = new DOMParser().parseFromString(body, "text/html");

//   const cards = Array.from(html.querySelectorAll(".post-card"));

//   const grid = document.querySelector("#posts");

//   if (!append) {
//     cards.reverse();
//   }

//   cards.map((c, index) => {
//     if (append) {
//       grid.appendChild(c);
//       if (index == 0) {
//         c.scrollIntoView();
//       }
//     } else {
//       grid.prepend(c);
//     }
//   });

//   const existingLoadMore = document.querySelector(".js-load-more");
//   const newLoadMore = html.querySelector(".js-load-more");

//   existingLoadMore.remove();

//   if (newLoadMore) {
//     grid.appendChild(newLoadMore);
//   }

//   handleLoadMorePosts();
// }

// // if page contains a div with .insights_posts and the datas
// let insightsPosts = document.querySelector("#posts");
// if (insightsPosts) {
//   let page = parseInt(insightsPosts.dataset.page);
//   let url = window.location.href;

//   let firstCard = document.querySelector("#posts .post-card");
//   console.log(firstCard);

//   while (page > 1) {
//     let previousPageUrl = url.replace(`/p${page}`, `/p${page - 1}`);
//     loadMore(previousPageUrl, false);
//     page--;
//   }

//   firstCard.scrollIntoView();
// }

const forms = Array.from(document.querySelectorAll("form.fui-form"));
if (forms.length > 0) {
  updateForms(forms);
}

async function getCsrfToken() {
  const response = await fetch("/actions/users/session-info", {
    headers: {
      Accept: "application/json",
    },
  });
  const json = await response.json();
  return json;
}

async function updateForms(forms) {
  const token = await getCsrfToken();
  forms.map((f) => {
    const csrfInput = f.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
    csrfInput.value = token.csrfTokenValue;
  });
}
